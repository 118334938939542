<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download"
				>{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}{{ typeView ? '健康资讯' : '惠民信息' }}</span
			>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="标题" prop="title">
				<el-input v-model.trim="ruleForm.title" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="来源" prop="origin" class="w50 mr16">
				<el-input v-model.trim="ruleForm.origin" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="类别" prop="bulletinTypeDictionaryItemId" class="w50 mr0">
				<el-select
					v-model="ruleForm.bulletinTypeDictionaryItemId"
					placeholder="请选择"
					:disabled="isShowDetail"
					@change="changeSelect($event, 'bulletinTypeDictionaryItemId')"
				>
					<el-option v-for="item in bulletinType" :key="item.id" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="上传文件" prop="fileList" class="uploadDataList">
				<el-upload
					:class="{ hide: isShowDetail }"
					action=""
					multiple
					:before-upload="beforeUpload"
					:http-request="uploadURL"
					:on-error="importError"
					:limit="1"
					:file-list="ruleForm.fileList"
					:on-remove="handleRemove"
				>
					<el-button type="primary" icon="el-icon-upload2" :disabled="isShowDetail">上传文件(PDF)</el-button>
				</el-upload>
			</el-form-item>
			<ImageAndVideo
				:imageData="ruleForm.images"
				ref="ImageAndVideo"
				:requires="true"
				title="上传图文内容"
				:disabled="isShowDetail"
				:modulePath="typeView ? 'HEALTH_MESSAGE' : 'PEOPLE_BENEFACTION'"
			></ImageAndVideo>
			<el-form-item label="上传视频" v-if="typeView == 'HealthCounseling'">
				<UploadVideo ref="UploadVideo" v-model="ruleForm.videos" operator="zhangl" :maxCount="6" :disabled="isShowDetail"></UploadVideo>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { client, headers, getFileNameUUID } from '@/utils/alioss.js';
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
		ImageAndVideo: () => import('@/components/ImageAndVideo/index.vue'),
		UploadVideo: () => import('@/components/Upload/video.vue'),
	},
	data() {
		return {
			createdVisible: false,
			ruleForm: {
				title: '',
				bulletinTypeDictionaryItemId: '',
				origin: '',
				content: '',
				fileList: [],
				images: [],
				videos: [],
			},
			bulletinType: [],
			rules: {
				title: [{ required: true, message: '请输入', trigger: 'blur' }],
				bulletinTypeDictionaryItemId: [{ required: true, message: '请选择', trigger: 'blur' }],
				origin: [{ required: true, message: '请输入', trigger: 'blur' }],
				content: [{ required: true, message: '请输入', trigger: 'blur' }],
				images: [{ required: true, message: '请上传', trigger: 'blur' }],
				fileList: [{ required: true, message: '请上传', trigger: 'blur' }],
			},
			saveLoading: false,
			link: '',
			attachmentInfo: [],
		};
	},
	props: ['isShowDetail', 'typeView'],
	watch: {},
	mounted() {},
	methods: {
		init(parkInfo) {
			this.createdVisible = true;
			this.link = parkInfo.link;
			this.getDeviceTypeList();
			this.getAttachment();
			if (parkInfo.row) {
				this.getDetail(parkInfo.link);
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			// 新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取详情
		getDetail(link) {
			this.$http
				.get(link)
				.then((res) => {
					if (res.data && res.data.success) {
						this.setRuleForm(res.data.collection[0]);
					}
				})
				.catch((e) => {});
		},
		// 修改/查看
		setRuleForm(data) {
			this.ruleForm = JSON.parse(JSON.stringify(data));
			this.ruleForm.bulletinTypeDictionaryItemId = data.bulletinTypeDictionaryItem?.id || null;
			this.ruleForm.fileList =
				(data.attachmentMap &&
					data.attachmentMap['DOCUMENT'] &&
					data.attachmentMap['DOCUMENT'].map((item) => {
						return {
							title: item.title,
							url: item.link,
							uid: item.id,
							name: item.title,
						};
					})) ||
				[];
			this.ruleForm.images =
				(data.attachmentMap &&
					data.attachmentMap['PHOTO'] &&
					data.attachmentMap['PHOTO'].map((item) => {
						return {
							basicInfoImg: [
								{
									title: item.title,
									url: item.link,
									id: item.id,
								},
							],
							description: item.description,
						};
					})) ||
				[];
			this.ruleForm.videos =
				(data.attachmentMap &&
					data.attachmentMap['VIDEO'] &&
					data.attachmentMap['VIDEO'].map((item) => {
						return {
							title: item.title,
							url: item.link,
							uid: item.id,
							name: item.title,
						};
					})) ||
				[];
		},
		onResetForm(formName) {
			this.createdVisible = false;
			this.$refs.ImageAndVideo.images = [];
			this.ruleForm.images = [];
			this.ruleForm.fileList = [];
			this.$refs[formName].resetFields();
		},
		changeSelect(val, type) {
			this.$set(this.ruleForm, type, val);
			this.$forceUpdate();
		},
		//保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let images =
						this.$refs.ImageAndVideo.images.filter((item) => {
							return item.basicInfoImg.length > 0;
						}) || [];
					if (images.length == 0) {
						this.$message.error('请上传图文内容');
						this.saveLoading = false;
						return;
					}
					let imageData = images.map((item) => {
						return {
							link: item.basicInfoImg?.find((v) => v.title).url,
							subjectTypeCode: this.attachmentInfo.find((iter) => iter.code == 'PHOTO').subjectTypeCode,
							title: item.basicInfoImg?.find((v) => v.title).title,
							attachmentGroupId: this.attachmentInfo.find((iter) => iter.code == 'PHOTO').id,
							mimeType: item.basicInfoImg?.find((v) => v.title).title.split('.')[1],
							description: item.description,
						};
					});
					let fileList = this.ruleForm.fileList.map((item) => {
						return {
							link: item.url,
							subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'DOCUMENT').subjectTypeCode,
							title: item.title,
							attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'DOCUMENT').id,
							mimeType: item.title.split('.')[1],
						};
					});
					let videoData = this.ruleForm.videos.map((item) => {
						return {
							link: item.url,
							subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'VIDEO').subjectTypeCode,
							title: item.title,
							attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'VIDEO').id,
							mimeType: item.title.split('.')[1],
						};
					});
					let params = {
						bulletinTypeDictionaryItemId: this.ruleForm.bulletinTypeDictionaryItemId,
						origin: this.ruleForm.origin,
						title: this.ruleForm.title,
						content: this.ruleForm.content,
						attachments: [...imageData, ...fileList, ...videoData],
						bulletinTypeCode: this.typeView ? 'H' : 'B',
					};
					let queryApi = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					queryApi;
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.saveLoading = false;
								this.onResetForm('ruleForm');
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}
			});
		},
		// 获取类别
		getDeviceTypeList() {
			let code = this.typeView ? 'HEALTH_MESSAGE' : 'PEOPLE_BENEFACTION';
			this.$http
				.get(this.api['DictionaryItems#index'].href, {
					params: { dictionaryCode: code, size: 9999, current: 1 },
				})
				.then((res) => {
					if (res.data && res.data.success) {
						this.bulletinType = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取附件组
		getAttachment() {
			this.$http
				.get(this.api['AttachmentGroups#index'].href, { params: { subjectTypeCode: 'BULLETIN' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 自定义上传
		uploadURL(file) {
			let fileName = 'bernieri/zhangl/PEOPLE_BENEFACTION' + `/${getFileNameUUID()}` + '.pdf';
			client()
				.multipartUpload(fileName, file.file, {
					headers,
				})
				.then((res) => {
					const path = 'https://qdincrease-cd.oss-cn-chengdu.aliyuncs.com/' + res.name;
					if (res.res.status == 200) {
						this.$message({
							type: 'success',
							message: '导入成功',
						});
						this.$loading().close();
						this.ruleForm.fileList.push({
							title: file.file.name,
							url: path,
							uid: file.file.uid,
							name: file.file.name,
						});
					}
				});
		},
		beforeUpload(file) {
			let lastLen = file.name.lastIndexOf('.');
			let len = file.name.len;
			let type = file.name.substring(lastLen, len);
			if (type !== '.pdf') {
				this.$message({
					type: 'error',
					message: '请导入pdf文件',
				});
				return false;
			}
			this.$loading({
				lock: true,
				text: '导入中',
				background: 'rgba(0, 0, 0, 0.7)',
				spinner: 'el-icon-loading',
			});
		},
		importError(err, file, fileList) {
			let msg = err.toString().split('"msg":')[1];
			let showMsg = msg.length > 0 ? msg.split('"') : [];
			this.$message({
				type: 'error',
				message: showMsg[1] || '导入失败',
			});
			this.$loading().close();
		},
		handleRemove(file, fileList) {
			this.ruleForm.fileList = fileList;
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
}

/deep/ .el-upload {
	.el-button--primary {
		border-color: #c4c4c4;
		color: #333;
		background-color: #fff;
		margin-left: 0;
	}
	.is-disabled {
		background-color: #f4f3f3 !important;
		border-color: #e4e7ed !important;
		color: #c0c4cc !important;
	}

	// .el-upload-list__item.is-success:focus:not(:hover) {
	// 	display: none !important;
	// }
}
// /deep/ .el-upload--picture-card {
// 	width: 100px;
// 	height: 100px;
// 	line-height: 100px;
// }
/deep/ .el-button {
	margin-left: 16px;
}
.hide {
	// display: none;
	/deep/ .el-upload-list {
		.el-icon-close,
		.el-icon-close-tip {
			display: none;
		}
	}
}

/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
</style>